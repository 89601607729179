import React from "react";
import "./summary.scss";
import WidgetBoard from "./Components/WidgetBoard";
import GridDuck from "gridduck";
import * as _ from 'underscore';
import processedUrlParams from "../../services/processDataPageUrlParams";
import GenericLoader from "../../components/GenericLoader";
import {Button, FilterMenu, Icon, StateOptions, Tooltip} from "gd-react";
import LegendRange from "../../components/LegendRange";

class DesktopSummaryPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.widgetBoardUpdated = this.widgetBoardUpdated.bind(this);
        this.saveBoard = this.saveBoard.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.getWidgetBoardData = this.getWidgetBoardData.bind(this);
        this.onDateRangeUpdate = this.onDateRangeUpdate.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.setAvailableTelemetries = this.setAvailableTelemetries.bind(this);
        this.groupAssetsByType = this.groupAssetsByType.bind(this);
        this.initialLoad = this.initialLoad.bind(this);
        this.onCompareUpdate = this.onCompareUpdate.bind(this);
        this.checkTelemetryPending = this.checkTelemetryPending.bind(this)
        this.resetBoard = this.resetBoard.bind(this)
        this.boardRef = React.createRef();
        this.state = {
            editingWidgetBoard: false,
            boardData: null,
            loading: true,
            isPendingData: false,
            fullscreen: false
        }
    }

    snakeCase = string => {
        return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join('_');
    };

    resetBoard() {
        this.widgetBoard = null;
        this.widgetBoardId = null;

        this.setState({
            editingWidgetBoard: false,
            boardData: null,
            loading: true,
            fullscreen: false,
            assets: null,
            availableTelemetries: null,
            loadedRange: false,
            loadedBoard: false
        }, () => {
            console.log('reset board: ', this.state);
            this.initialLoad();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.props.itemId, ' : this.props.id ');
        if (this.props.itemId && this.props.itemId !== prevProps.itemId) {
            console.log('reset board because ID changed: ', this.props.itemId);
            this.resetBoard();
        }
    }

    componentDidMount() {
        let self = this
        if (this.props.item) {
            this.initialLoad();
            if (this.props.itemId.indexOf('all') !== -1) {
                this.checkTelemetryPending().then((bool) => {
                    self.setState({isPendingData: bool})
                })
            }
        }
    }


    componentWillUnmount() {
        GridDuck.abortAllRequests("Left Summary Page");
    }

    async initialLoad() {
        let dataItems = this.props.dataItems;

        if (dataItems) {
            this.setState({
                assets: this.groupAssetsByType(dataItems),
                flatAssets: dataItems,
            });
        } else if (this.props.className === 'asset-page') {
            this.setState({
                assets: this.groupAssetsByType([this.props.item]),
                flatAssets: [this.props.item],
            });
        }
        this.widgetBoardId = this.props.item.widgetBoardId;

        if (this.widgetBoardId) {
            this.getWidgetBoardData();
        } else {
            this.setState({loading: false, loadedBoard: true, availableTelemetries: this.props.availableDataTypes});
        }
    }

    groupAssetsByType(assets) {
        let returnAssets = {};
        let self = this;
        assets.forEach(function (asset) {
            asset.parent = asset.parentAsset;
            let stateOptions = StateOptions.find(t => t.type === asset.sku);
            if (stateOptions) {
                stateOptions.dataTypes.forEach(so => {
                    let catArr = returnAssets[self.snakeCase(so.category)]
                    if (!catArr) returnAssets[self.snakeCase(so.category)] = [];
                    returnAssets[self.snakeCase(so.category)].push(asset);
                    returnAssets[self.snakeCase(so.category)] = _.uniq(returnAssets[self.snakeCase(so.category)], x => x.id);
                })
            }
        });
        return returnAssets;
    }

    setAvailableTelemetries(assets) {
        let telems = [];
        let self = this;
        assets.forEach(function (asset) {
            let stateOptions = StateOptions.find(t => t.type === asset.sku);
            if (stateOptions) {
                stateOptions.dataTypes.forEach(function (dataType) {
                    if ((!telems.find(dT => dT === dataType.category))) {
                        if (dataType.category !== 'Signal') telems.push(self.snakeCase(dataType.category))
                    }
                });
            }
        });
        return telems;
    }

    onDateRangeUpdate(start, end, a, granularity, rangeStringObj) {
        let wb = this.state.boardData;
        if (!wb) wb = {dateData: {}};
        if (!wb.dateData) wb.dateData = {};
        wb.dateData.start = start;
        wb.dateData.end = end;
        wb.dateData.d_rstring = rangeStringObj.dRString;
        wb.dateData.c_rstring = rangeStringObj.cRString;
        wb.dateData.compare_start = a ? a.start : null;
        wb.dateData.compare_end = a ? a.end : null;
        wb.firstTime = rangeStringObj.firstTime;
        console.log(wb.firstTime, ' : wb firstTime');
        this.widgetBoardUpdated(wb, true, () => {
            if (!wb.firstTime) {
                this.saveBoard();
            }
        });
    }

    onCompareUpdate(val) {
        // this.compareUpdate = true;
        // this.onDateRangeUpdate(this.state.boardData.start, this.state.boardData.end, null, this.state.boardData.granularity, {dRString: this.state.boardData.d_rstring, cRString: val})
    }

    async checkTelemetryPending() {
        let userData = await GridDuck.getAccount({id: GridDuck.userId})
        let filters = [
            {
                field: 'organisation_id',
                value: userData.id
            },
            {
                field: 'status',
                value: 'STARTED'
            },
        ]
        let req = await GridDuck.getTelemetryOperations({filters: filters})
        return !!(req.list?.length)
    }

    getWidgetBoardData(doneEditing) {
        let self = this;
        let start, end, compareStart, compareEnd, granularity, dRString, cRString;
        const widgetBoardIdLoading = this.widgetBoardId + '';
        return GridDuck.getWidgetBoard({id: this.widgetBoardId}).then(function (wb) {
            if (widgetBoardIdLoading !== self.widgetBoardId) {
                return;
            }
            console.log(wb.columnData, " : columnData");
            // LEGACY FIX FOR PREVIOUS GAS WIDGET BOARDS
            Object.entries(wb.columnData).forEach(function ([id, column]) {
                console.log(column, ' : column for widget')
                column.widget_ids = column.widget_ids.map(widgetId => {
                    if (widgetId.indexOf('gas') !== -1 && (widgetId.indexOf('volume') === -1 && widgetId.indexOf('energy') === -1)) {
                        return widgetId.replace('gas', 'gas_volume');
                    } else return widgetId;
                })
            });
            let dd = wb.dateData;
            let virtualURLParams = {
                get: function (str) {
                    switch (str) {
                        case 'dR':
                            return dd.d_rstring;
                            break;
                        case 'cR':
                            return dd.c_rstring;
                            break;
                        case 's':
                            return dd.start;
                            break;
                        case 'e':
                            return dd.end;
                            break;
                        case 'cE':
                            return dd.compare_end;
                            break;
                        case 'cS':
                            return dd.compare_start;
                            break;
                    }
                }
            };
            // let processed = processedUrlParams(virtualURLParams, dd.timezone ? dd.timezone : 'Europe/London');
            self.widgetBoard = wb;

            // wb.dateData.start = null;
            // wb.dateData.end = null;
            // wb.dateData.compareStart = null;
            // wb.dateData.compareEnd = null;
            // if (processed.compareStart) {
            // 	wb._dateData.compare_start = processed.compareStart;
            // }
            // if (processed.compareEnd) {
            // 	wb._dateData.compare_end = processed.compareEnd;
            // }
            self.widgetBoardUpdated(wb, doneEditing);
        });
    }

    widgetBoardUpdated(boardState, doneEditing, cb) {
        console.log(boardState, ' BS');
        let updateObj = this.state.boardData ? this.state.boardData : {};
        if (boardState.columnData) updateObj.columnData = boardState.columnData;
        if (boardState.columnOrder) updateObj.columnOrder = boardState.columnOrder;
        if (boardState.dateData) updateObj.dateData = boardState.dateData;

        console.log(this.state.loadedRange || boardState.firstTime, ' : this.state.loadedRange || boardState.firstTime');
        let state = {
            boardData: updateObj,
            availableTelemetries: this.props.availableDataTypes,
            loadedRange: this.state.loadedRange || boardState.firstTime,
            loading: false,
            loadedBoard: true
        }
        if (doneEditing) state.editingWidgetBoard = false;
        this.setState(state, (data) => {
            if (cb) cb(data);
        })
    }

    cancelEditing() {
        return new Promise((resolve, reject) => {
            this.setState({loading: true, editingWidgetBoard: false, boardData: null}, () => {
                resolve();
                if (this.widgetBoardId) {
                    this.getWidgetBoardData(true);
                } else {
                    this.setState({loading: false, loadedBoard: true});
                }
            });
        });
    }

    setFullScreen() {
        this.setState({hideFsButton: true}, () => {
            this.setState({fullscreen: !this.state.fullscreen, hideFsButton: false})
        })
    }

    saveBoard() {
        let site = this.props.item;
        let self = this;

        return new Promise((resolve, reject) => {
            self.setState({loading: true}, () => {
                if (!site.widgetBoardId) {
                    GridDuck.createWidgetBoard({
                        columnData: self.state.boardData.columnData,
                        columnOrder: self.state.boardData.columnOrder,
                        dateData: self.state.boardData.dateData,
                        itemId: site.id === 'all' ? null : site.id
                    }).then((res) => {
                        self.widgetBoardId = res.id;
                        self.getWidgetBoardData(true);
                        resolve();
                    });
                } else {
                    if (self.widgetBoard) self.widgetBoard.set({
                        columnData: self.state.boardData.columnData,
                        columnOrder: self.state.boardData.columnOrder,
                        dateData: self.state.boardData.dateData
                    }).then((res) => {
                        resolve();
                        self.setState({editingWidgetBoard: false, loading: false});
                    });

                }
            });
        })
    }

    render() {
        let self = this;
        console.log(this.state, ' : this.state.boardData?.dateData?');
        let hasDateData = this.state.boardData && this.state.boardData.dateData;
        if (hasDateData) {
            console.log(this.state.boardData, ' : has board data');
        }
        return (
            <div className={'page narrow not-flex grey summary-page ' + (this.state.fullscreen ? 'fullscreen' : '')}>
                <div className={'widget-board-header'}>
                    {!this.state.editingWidgetBoard ? <div style={{display: 'flex', alignItems: 'center'}}>
                        <Button disabled={this.state.loading} color={'gd-grey'} label={'Edit'}
                                outline
                                onClick={(val) => this.setState({editingWidgetBoard: true})}/>
                        <Tooltip label={'Refresh'}>
                            <div className={'button-wrapper grey larger'}
                                 style={{marginLeft: '10px'}}
                                 onClick={this.cancelEditing}><Icon
                                size={11}
                                color={'#808080'}
                                icon={'FaSync'}/>
                            </div>
                        </Tooltip>
                        {!this.state.hideFsButton ?
                            <Tooltip label={this.state.fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}>
                                <div className={'button-wrapper grey larger'}
                                     style={{marginLeft: '10px'}}
                                     onClick={this.setFullScreen}><Icon
                                    size={12}
                                    color={'#808080'}
                                    icon={this.state.fullscreen ? 'FaCompress' : 'FaExpand'}/>
                                </div>
                            </Tooltip> : null}
                    </div> : null}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {this.state.editingWidgetBoard ?
                            <Button disabled={this.state.loading} color={'gd-grey'} additionalclasses={'sm'} progressRes
                                    label={'Cancel'}
                                    outline
                                    onClick={this.cancelEditing}/> : null}
                        {this.state.editingWidgetBoard ?
                            <Button disabled={this.state.loading} progressRes label={'Save'} additionalclasses={'sm'}
                                    onClick={this.saveBoard}/> : null}
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {this.state.editingWidgetBoard ?
                            <Button disabled={this.state.loading} style={{marginLeft: 0}} color={'gd-grey'}
                                    label={'Auto Layout'}
                                    outline
                                    onClick={this.boardRef.current.resetLayout}/> : null}
                        {this.state.editingWidgetBoard && this.state.boardData.columnOrder.length < 10 ?
                            <Button disabled={this.state.loading} color={'gd-grey'} label={'Add Column'}
                                    outline
                                    onClick={this.boardRef.current.addColumn}/> : null}
                        {this.state.editingWidgetBoard ?
                            <FilterMenu onUpdate={this.boardRef.current.onChangeFilter}
                                        filterItems={this.boardRef.current.state.filterOptions}>
                                <Button disabled={this.state.loading} outline color={'gd-grey'}
                                        label={'Select Widgets'}/>
                            </FilterMenu> : null}
                    </div>

                    {!this.state.editingWidgetBoard && this.props.loaded && this.state.loadedBoard ?
                        <LegendRange
                            summaryMode
                            disabled={this.state.loading}
                            category={'Electricity'}
                            dRString={hasDateData ? this.state.boardData.dateData.d_rstring : null}
                            cRString={hasDateData ? this.state.boardData.dateData.c_rstring : null}
                            granularity={hasDateData ? this.state.boardData.dateData.granularity : null}
                            onHover={hasDateData ? this.state.boardData.dateData.onHover : null}
                            onHide={hasDateData ? this.state.boardData.dateData.onHide : null}
                            resetZoom={hasDateData ? this.state.boardData.dateData.resetZoom : null}
                            zoomData={hasDateData ? this.state.boardData.dateData.zoomData : null}
                            defaultSelected={hasDateData ? this.state.boardData.dateData.defaultSelected : null}
                            graph={'bar'}
                            graphType={hasDateData ? this.state.boardData.dateData.graphType : null}
                            timezone={this.props.item.timezone || 'Europe/London'}
                            time={hasDateData ? this.state.boardData.dateData.time : null}
                            start={hasDateData ? this.state.boardData.dateData.start : null}
                            end={hasDateData ? this.state.boardData.dateData.end : null}
                            set={this.onDateRangeUpdate}
                            updateonload
                            onIsComparingChange={() => {
                            }}
                            isAll={this.props.itemId.indexOf('all') !== -1}
                            compareStart={hasDateData ? this.state.boardData.dateData.compare_start : null}
                            compareEnd={hasDateData ? this.state.boardData.dateData.compare_end : null}
                        /> : null}
                </div>
                {!this.state.loading && this.props.loaded && this.state.loadedRange && this.state.boardData && this.state.boardData.dateData && this.state.availableTelemetries ?
                    <WidgetBoard timezone={this.props.item.timezone || 'Europe/London'}
                                 ref={this.boardRef}
                                 item={this.props.item}
                                 type={this.props.type}
                                 siteGroupString={this.props.siteGroupString}
                                 reload={this.cancelEditing}
                                 className={this.props.className}
                                 filterType={this.props.filterType}
                                 dataItems={this.props.dataItems}
                                 filterId={this.props.filterId}
                                 onCompareUpdate={this.onCompareUpdate}
                                 fullscreen={this.state.fullscreen} setFullScreen={this.setFullScreen}
                                 onDateRangeUpdate={this.onDateRangeUpdate} onCancel={this.cancelEditing}
                                 widgetBoardId={this.widgetBoard ? this.widgetBoard.id : this.props.item.widgetBoardId}
                                 isEditing={(val) => this.setState({editingWidgetBoard: val})}
                                 boardData={this.state.boardData} saveBoard={this.saveBoard}
                                 availableTelemetries={this.state.availableTelemetries}
                                 isPendingData={this.state.isPendingData}
                                 onChange={function (boardState, doneEditing, cb) {
                                     self.widgetBoardUpdated(boardState, doneEditing, cb);
                                 }} editing={this.state.editingWidgetBoard}/> : null}
                {this.state.loading || !this.props.loaded || !this.state.loadedRange ?
                    <GenericLoader text={'Fetching'} textLineTwo={'Widgets...'}/> : null}
            </div>
        );
    }
}

export default DesktopSummaryPage;